<template>
  <CommonTooltip :tooltip="$t('cars-list.taxation-notes')">
    <component
      :is="taxPopupSlug ? CommonLink : 'span'"
      v-bind="{
        ...$attrs,
        ...(taxPopupSlug
          ? {
              href: `#popup-${taxPopupSlug}`
            }
          : {})
      }"
      @mouseenter="preparePopupData"
      @click.stop="preparePopupData"
    >
      {{ label ?? car.taxInformation }}
    </component>
  </CommonTooltip>
</template>

<script lang="ts" setup>
import type { AuctionCar } from '@autobid/ui/types/Car'
import { useTaxationPopup } from '@autobid/ui/composables/car/useTaxationPopup'
import CommonLink from '@autobid/ui/components/common/Link.vue'

interface Props {
  car: AuctionCar
  label?: string
  taxPopupSlug?: string
}

const props = defineProps<Props>()
const { setTaxPopupData } = useTaxationPopup()
const vatRate = props.car.additionalInformation?.itemLocationCountry?.vatRate

const preparePopupData = () => {
  if (!props.taxPopupSlug || !vatRate) return

  setTaxPopupData(props.taxPopupSlug, vatRate)
}
</script>
