import { usePopupStore } from '@autobid/nuxt-pinia-store/store/usePopupStore'
import { storeToRefs } from 'pinia'

export function useTaxationPopup() {
  const { cmsPopupsParams } = storeToRefs(usePopupStore())

  const setTaxPopupData = (taxPopupSlug: string, vatRate: number) => {
    cmsPopupsParams.value[taxPopupSlug] = {
      percent: String(vatRate)
    }
  }

  return {
    setTaxPopupData
  }
}
